import React, { useState, useEffect } from 'react';
import 'whatwg-fetch';
import * as faceapi from 'face-api.js';
import { Link } from 'react-router-dom';
import loadImage from 'blueimp-load-image';
import Header from '../layout/header';
import './style.css';
import { api } from '../../redux/config';
import axios from 'axios';
import { getGPUTier } from 'detect-gpu';

const Transformator = () => {

  const [active, setActive] = useState(true);
  const [tumbler, setTumbler] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [status, setStatus] = useState('не выполнена');
  const [photoShare, setPhotoShare] = useState(null);
  const [popup, setPopup] = useState(null);

  const [fromEmail, setFromEmail] = useState('');
  const [toEmail, setToEmail] = useState('');
  const [mailText, setMailText] = useState('');

  const [defaultMask, setDefaultMask] = useState(null);
  const [defaultGl, setDefaultGl] = useState(null);

  const [gpu, setGpu] = useState({
    gpu: true,
    msg: false
  });

  useEffect(() => {
    gpuDetect();

    run();

    if(window.defaultmasktimer){
      clearInterval(window.defaultmasktimer);
    }

    const h = [
      '/images/transformator/default/m1.png',
      '/images/transformator/default/m2.png',
      '/images/transformator/default/m3.png',
      '/images/transformator/default/m4.png',
    ]

    const g = [
      '/images/transformator/default/d1.png',
      '/images/transformator/default/d2.png',
      '/images/transformator/default/d3.png',
      '/images/transformator/default/d4.png',
      '/images/transformator/new/pix.png',
    ]

    setDefaultMask(h[ Math.round(Math.random() * (h.length - 1) ) ]);
    setDefaultGl(g[ Math.round(Math.random() * (g.length - 1) ) ]);

    window.defaultmasktimer = setInterval(() => {
      setDefaultMask(h[ Math.round(Math.random() * (h.length - 1) ) ]);
      setDefaultGl(g[ Math.round(Math.random() * (g.length - 1) ) ]);
    }, 500);
  },[]);

  async function run() {
    await faceapi.nets.ssdMobilenetv1.loadFromUri('/models');
    await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
    setActive(false);
  }

  function drawImageByWidth(image, ctx, width, center){
    var newHeight = ( width / image.width ) * image.height;
    ctx.drawImage(image, center[0] - width / 2, center[1] - newHeight / 2, width, newHeight);
  }

  const gpuDetect = () => {
    const obj = {...gpu};
    const GPUTier = getGPUTier();
    const array = ['GPU_DESKTOP_TIER_1', 'GPU_DESKTOP_TIER_2', 'GPU_DESKTOP_TIER_3', 'GPU_MOBILE_TIER_1', 'GPU_MOBILE_TIER_2', 'GPU_MOBILE_TIER_3', 'BLACKLISTED'];
    if(array.includes(GPUTier.tier)){
      if(GPUTier.tier == 'GPU_DESKTOP_TIER_1' || GPUTier.tier == 'GPU_MOBILE_TIER_1'){
        obj.msg = true
      }
    }
    else{
      obj.gpu = false
      obj.msg = true
    }
    setGpu(obj);
  }

  async function predict(images){
    const canvas = document.getElementById('faceapi');
    const ctx = canvas.getContext("2d");

    const input = images.photo;
    var outAll = await faceapi.detectAllFaces(input).withFaceLandmarks();

    document.getElementById('transformator-file1').value = '';
    document.getElementById('transformator-file2').value = '';
    
    if(outAll.length && outAll.length === 1){
      const out = outAll[0];
      var marks = out.landmarks._positions;
      var imgWidth = out.detection._imageDims._width;
      var imgHeight = out.detection._imageDims._height;
      canvas.width = imgWidth;
      canvas.height = imgHeight;
      ctx.clearRect(0, 0, imgWidth, imgHeight);
      ctx.drawImage(input, 0, 0);
  
      for(let k in images){
        if(k === 'element1'){
          let coef = 2.3;
          if(images[k].getAttribute('src').split('/').indexOf('new') !== -1){
            coef = 2;
          }
          const width = marks[16]._x - marks[0]._x;
          const center = [ marks[0]._x + (marks[16]._x - marks[0]._x) / 2 , marks[21]._y - (marks[8]._y - marks[27]._y) / 2 ];
          drawImageByWidth(images[k], ctx, width * coef, center);
        }
  
        if(k === 'element2'){
          const width = marks[45]._x - marks[36]._x;
          const center = [ marks[27]._x , marks[27]._y + (marks[28]._y - marks[27]._y) / 2 ];
          drawImageByWidth(images[k], ctx, width * 1.6, center);
        }
  
        setPhotoShare(canvas.toDataURL('image/jpeg', 1));
  
        /*for(var i = 0; i < marks.length; i++){
          ctx.font = '12px sans-serif';
          ctx.fillStyle = 'red';
          ctx.fillText(i, marks[i]._x, marks[i]._y);
        }*/
      }
    }
    else{
      const cs = 344;
      canvas.width = cs;
      canvas.height = cs;
      ctx.clearRect(0, 0, cs, cs);

      const b = [
        '/images/transformator/new/border1.png',
        '/images/transformator/new/border2.png',
        '/images/transformator/new/border3.png',
        '/images/transformator/new/border4.png',
      ]

      const img = new Image();
      img.onload = function(){
        if(images.photo.width > images.photo.height){
          const h = images.photo.height * ( cs / images.photo.width );
          ctx.drawImage(images.photo, 0, (cs - h) / 2, cs, h);
        }
        else{
          const w = images.photo.width * ( cs / images.photo.height );
          ctx.drawImage(images.photo, (cs - w) / 2, 0, w, cs);
        }
  
        ctx.drawImage(img, 0, 0);

        setPhotoShare(canvas.toDataURL('image/jpeg', 1));
      }
      img.src = b[Math.round(Math.random() * ( b.length - 1 ) )];
    }

    setStatus('Трансформировано');
    setActive(false);
    setTumbler(false);
  }

  const loadImages = (sources, callback) => {
    const images = {};
    let loadedImages = 0;
    let numImages = 0;
    for(var src in sources) {
      numImages++;

      images[src] = new Image();
      images[src].onload = () => {
        if(++loadedImages >= numImages) {
          callback(images);
        }
      };
      images[src].src = sources[src];
    }
  }

  const change = (input) => {
    if (input.target.files && input.target.files[0]) {
      const canvas = document.getElementById('faceapi');
      const ctx = canvas.getContext("2d");
      setStatus('не выполнена');
      setPhotoShare(null);

      loadImage(
        input.target.files[0],
        img => {
          ctx.clearRect(0, 0, img.width, img.height);
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);

          setPhoto(img.toDataURL('image/jpeg', 1));
          setTumbler(false);
        },
        {
          maxWidth: 500,
          orientation: true,
          canvas: true
        }
      )
    }
  }

  const tumblerPredict = () => {
    if(photo && !tumbler){
      setTumbler(true);
      setStatus('Пожалуйста, подождите');

      const h = [
        '/images/transformator/new/mn1.png',
        '/images/transformator/new/mn2.png',
        '/images/transformator/new/mn3.png',
        '/images/transformator/new/mn4.png',
        '/images/transformator/m1.png',
        '/images/transformator/m2.png',
        '/images/transformator/m3.png',
        '/images/transformator/m4.png',
        '/images/transformator/m5.png',
      ]

      const g = [
        '/images/transformator/e1.png',
        '/images/transformator/gn1n.png',
        '/images/transformator/gn2.png',
        '/images/transformator/gn3.png',
        '/images/transformator/new/pix.png',
      ]

      const rand1 = Math.round(Math.random() * (h.length - 1) );
      const rand2 = Math.round(Math.random() * (g.length - 1) );

      const sources = {
        photo,
        element1: h[rand1],
        element2: g[rand2],
      }
  
      loadImages(sources, function(images){
        predict(images);
      });
    }
  }

  const sharing = vendor => {
    const sourcesAll = {
      photo: photoShare,
      bgFb: '/images/transformator/sharing_tmp2n.jpg',
      bgVk: '/images/transformator/sharing_tmp2n.jpg' //'/images/transformator/sharing_tmp1.jpg',
    }

    loadImages(sourcesAll, function({photo, bgFb, bgVk}){
      const canvasFb = document.getElementById('faceapi-sharing-fb');
      const ctxFb = canvasFb.getContext("2d");
      const canvasVk = document.getElementById('faceapi-sharing-vk');
      const ctxVk = canvasVk.getContext("2d");

      const wh_fb = 493;
      const t_fb = 66;
      const l_fb = 100;

      /*const wh_vk = 387;
      const t_vk = 55;
      const l_vk = 84;*/

      const wh_vk = 493;
      const t_vk = 66;
      const l_vk = 100;

      canvasFb.width = bgFb.width;
      canvasFb.height = bgFb.height;
      ctxFb.clearRect(0, 0, bgFb.width, bgFb.height);
      ctxFb.drawImage(bgFb, 0, 0);

      if(photo.width > photo.height){
        const h = photo.height * ( wh_fb / photo.width );
        ctxFb.drawImage(photo, l_fb, t_fb + (wh_fb - h) / 2, wh_fb, h);
      }
      else{
        const w = photo.width * ( wh_fb / photo.height );
        ctxFb.drawImage(photo, l_fb + (wh_fb - w) / 2, t_fb, w, wh_fb);
      }

      canvasVk.width = bgVk.width;
      canvasVk.height = bgVk.height;
      ctxVk.clearRect(0, 0, bgVk.width, bgVk.height);
      ctxVk.drawImage(bgVk, 0, 0);

      if(photo.width > photo.height){
        const h = photo.height * ( wh_vk / photo.width );
        ctxVk.drawImage(photo, l_vk, t_vk + (wh_vk - h) / 2, wh_vk, h);
      }
      else{
        const w = photo.width * ( wh_vk / photo.height );
        ctxVk.drawImage(photo, l_vk + (wh_vk - w) / 2, t_vk, w, wh_vk);
      }

      setPopup(vendor);

    })
  }

  const shareSoc = () => {
    const canvasFb = document.getElementById('faceapi-sharing-fb');
    const canvasVk = document.getElementById('faceapi-sharing-vk');

    if(popup){
      const formData = new FormData();
      formData.append('PHOTO_DATA_FB', canvasFb.toDataURL('image/jpeg', 0.7));
      formData.append('PHOTO_DATA_VK', canvasVk.toDataURL('image/jpeg', 0.7));
  
      axios.post(api + 'upload-photo.php', formData)
      .then(function (response) {
        if(response.data.success){
          if(popup === 'fb'){
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${response.data.url['PHOTO_DATA_FB']}`,"sharingfb","menubar=1,resizable=1,width=500,height=500");
          }
          else{
            window.open(`https://vk.com/share.php?url=${window.location.protocol}//${window.location.host}&title=С 8 Марта!&description=С 8 Марта!&image=${window.location.protocol}//${window.location.host}${response.data.src['PHOTO_DATA_VK']}`, "sharing","menubar=1,resizable=1,width=500,height=500");
          }

          setPopup(null);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }

  const sharingMail = () => {
    const sourcesAll = {
      photo: photoShare,
      bg: '/images/transformator/sharing_tmp2n.jpg',
    }

    loadImages(sourcesAll, function({photo, bg}){
      const canvas = document.getElementById('faceapi-sharing-mail');
      const ctx = canvas.getContext("2d");

      const wh = 493;
      const t = 66;
      const l = 100;

      canvas.width = bg.width;
      canvas.height = bg.height;
      ctx.clearRect(0, 0, bg.width, bg.height);
      ctx.drawImage(bg, 0, 0);

      if(photo.width > photo.height){
        const h = photo.height * ( wh / photo.width );
        ctx.drawImage(photo, l, t + (wh - h) / 2, wh, h);
      }
      else{
        const w = photo.width * ( wh / photo.height );
        ctx.drawImage(photo, l + (wh - w) / 2, t, w, wh);
      }

      setPopup('mail');
    })
  }

  const validateEmail = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const sendMail = () => {
    const canvas = document.getElementById('faceapi-sharing-mail');
    
    const formData = new FormData();
    formData.append('FROM', fromEmail);
    formData.append('TO', toEmail);
    formData.append('TEXT', mailText);
    formData.append('IMAGE_DATA', canvas.toDataURL('image/jpeg', 0.7));

    axios.post(api + 'send-postcard.php', formData)
    .then(function (response) {
      if(response.data.success){
        setPopup('mail-ok');
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  return(
    <>
      <div className={popup === 'fb' ? 'transformator-preview active' : 'transformator-preview'}>
        <div className="transformator-preview-bg" onClick={() => setPopup(null)}></div>
        <div className="transformator-preview-box">
          <canvas id="faceapi-sharing-fb" />
          <img src="/images/transformator/new/p3.png" />
          <div className="transformator-preview-button" onClick={shareSoc}>
            <img src="/images/transformator/new/p2.png" />
            <img src="/images/transformator/new/p1.png" />
          </div>
        </div>
      </div>

      <div className={popup === 'vk' ? 'transformator-preview active' : 'transformator-preview'}>
        <div className="transformator-preview-bg" onClick={() => setPopup(null)}></div>
        <div className="transformator-preview-box">
          <canvas id="faceapi-sharing-vk" />
          <img src="/images/transformator/new/p3.png" />
          <div className="transformator-preview-button" onClick={shareSoc}>
            <img src="/images/transformator/new/p2.png" />
            <img src="/images/transformator/new/p1.png" />
          </div>
        </div>
      </div>


      <div className={popup === 'mail' ? 'tpopup tpopup-mail active' : 'tpopup tpopup-mail'}>
        <div className="tpopup-bg" onClick={() => setPopup(null)}></div>
        <div className="tpopup-mail-in">
          <img className="tpopup-mail-in-img" src="/images/transformator/new2/pmail1.png" />

          <svg className="tpopup-close" onClick={() => setPopup(null)} width="44pt" height="43pt" viewBox="0 0 44 43" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="#ff2f32ff">
              <path fill="#ff2f32" opacity="1.00" d=" M 7.13 0.00 L 7.54 0.00 C 12.59 4.59 17.26 9.59 22.15 14.36 C 27.17 9.84 32.12 5.23 37.13 0.69 C 39.54 3.13 41.60 5.92 44.00 8.35 L 44.00 8.59 C 38.94 12.73 34.23 17.28 29.47 21.75 C 34.15 26.33 38.74 31.01 43.39 35.62 C 41.10 37.98 38.67 40.25 36.83 43.00 L 35.58 43.00 C 31.64 37.68 26.37 33.47 21.85 28.64 C 16.83 33.17 11.88 37.77 6.87 42.31 C 4.43 39.93 2.60 36.99 0.00 34.76 L 0.00 34.24 C 5.20 30.35 9.70 25.61 14.56 21.32 C 9.93 16.63 5.17 12.06 0.63 7.28 C 2.93 4.98 5.40 2.79 7.13 0.00 Z" />
            </g>
          </svg>

          <canvas id="faceapi-sharing-mail" />

          <div className="tpopup-mail-form">
            <div>
              <input type="text" placeholder="e-mail коллеги" onChange={e => setToEmail(e.target.value)} maxLength="30" />
              <input type="text" placeholder="от кого" onChange={e => setFromEmail(e.target.value)} maxLength="30" />
            </div>
            <div>
              <textarea placeholder="Текст поздравления" onChange={e => setMailText(e.target.value)} maxLength="300" />
            </div>
          </div>

          <div onClick={sendMail} className={ (validateEmail(toEmail) && mailText ) ? 'tpopup-mail-button' : 'tpopup-mail-button disabled'}>
            <img src="/images/transformator/new2/pmail3.png" />
            <img src="/images/transformator/new2/pmail2.png" />
          </div>
        </div>
      </div>

      <div onClick={() => setPopup(null)} className={popup === 'mail-ok' ? 'tpopup tpopup-mail-ok active' : 'tpopup tpopup-mail-ok'}>
        <div className="tpopup-bg"></div>
        <div className="tpopup-mail-ok-in">
          <svg className="tpopup-close" width="44pt" height="43pt" viewBox="0 0 44 43" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="#ff2f32ff">
              <path fill="#ff2f32" opacity="1.00" d=" M 7.13 0.00 L 7.54 0.00 C 12.59 4.59 17.26 9.59 22.15 14.36 C 27.17 9.84 32.12 5.23 37.13 0.69 C 39.54 3.13 41.60 5.92 44.00 8.35 L 44.00 8.59 C 38.94 12.73 34.23 17.28 29.47 21.75 C 34.15 26.33 38.74 31.01 43.39 35.62 C 41.10 37.98 38.67 40.25 36.83 43.00 L 35.58 43.00 C 31.64 37.68 26.37 33.47 21.85 28.64 C 16.83 33.17 11.88 37.77 6.87 42.31 C 4.43 39.93 2.60 36.99 0.00 34.76 L 0.00 34.24 C 5.20 30.35 9.70 25.61 14.56 21.32 C 9.93 16.63 5.17 12.06 0.63 7.28 C 2.93 4.98 5.40 2.79 7.13 0.00 Z" />
            </g>
          </svg>

          <img className="tpopup-mail-ok-in__bg" src="/images/transformator/new2/ok2.png" />
          <img className="tpopup-mail-ok-in__el1" src="/images/transformator/new2/ok1.png" />
          <img className="tpopup-mail-ok-in__el2" src="/images/transformator/new2/ok3.png" />
        </div>
      </div>
      

      <div className={popup === 'mail' ? 'transformator-wrapper popup-active' : 'transformator-wrapper'}>
        <Header style={1} />
        <div className="transformator page fade-in">
          <div className={active ? 'transformator__load active' : 'transformator__load'}>
              <img src="/images/transformator/loader.png" />
          </div>

          <div className="spacer">
            <img src="/images/transformator/spacer.png" />

            <div className="transformator__title">
              <div>
                <img src="/images/transformator/title_new.png" />
              </div>
            </div>
            
            <div className={ ( photo && status == 'Трансформировано' ) ? 'transformator__file-wrap-hand transformator__file-wrap-hand3 active' : 'transformator__file-wrap-hand transformator__file-wrap-hand3'}>
              <img src="/images/transformator/hand.svg" />
            </div>

            <div className="transformator__box">
              <img className="transformator__box-el3" src="/images/transformator/new/el3.png" />
              <img className="transformator__box-el4" src="/images/transformator/new/el4.png" />
              <img className="transformator__box-el5" src="/images/transformator/new/el5.png" />
              <img className="transformator__box-el6" src="/images/transformator/new/el6.png" />
              <img className="transformator__box-el7" src="/images/transformator/new/el7.png" />

              <img className="transformator__box-img" src="/images/transformator/new/el13.png" />
              <img className="transformator__box-img2" src="/images/transformator/new/el1.png" />
              <img className="transformator__box-img3" src="/images/transformator/new/el2.png" />

              <div className="transformator__megatron">
                <img src="/images/transformator/new/megatron.png" />

                <div className="transformator__megatron-pop">
                  <img src="/images/transformator/new/el10n.png" />

                  <Link to="/start" className="transformator__megatron-pop-button">
                    <img src="/images/transformator/new/el8.png" />
                    <img src="/images/transformator/new/el9n.png" />
                  </Link>
                </div>
              </div>

              <div className={!photo ? 'transformator__file-wrap-hand active' : 'transformator__file-wrap-hand'}>
                <img src="/images/transformator/hand.svg" />
              </div>

              <div className={( !!photo && status != 'Трансформировано' ) ? 'transformator__file-wrap-hand transformator__file-wrap-hand2 active' : 'transformator__file-wrap-hand transformator__file-wrap-hand2'}>
                <img src="/images/transformator/hand.svg" />
              </div>

              <div className={!photo ? 'transformator__file active' : 'transformator__file'}>
                <img src="/images/transformator/new/el11.png" />
                <input id="transformator-file1" type="file" className="input" onChange={change} accept="image/*" />
              </div>

              <div className={!photo ? 'transformator__file2 active' : 'transformator__file2'}>
                <img src="/images/transformator/new/el12.png" />
                <input id="transformator-file2" type="file" className="input" onChange={change} accept="image/*" />
              </div>

              <div data-active={!!photo} className={ (photo && status != 'Трансформировано') ? 'transformator__tumbler active' : 'transformator__tumbler'} onClick={tumblerPredict}>
                <div className={tumbler ? 'transformator__tumbler-w' : 'transformator__tumbler-w active'}>

                  <img className="transformator__tumbler-bg" src="/images/transformator/box_el2.png" />
                  <div className="transformator__tumbler-t-wrap">
                    <img className="transformator__tumbler-t" src="/images/transformator/tumbler.png" />
                  </div>
                  
                  <div className="transformator__tumbler-circle transformator__tumbler-circle1" />
                  <div className="transformator__tumbler-circle transformator__tumbler-circle2" />
                </div>
              </div>
              <div className="transformator__screen">
                <img src="/images/transformator/box_screen.png" />

                <div className="transformator__canvas" data-active={!!photo}>

                  <img src={defaultGl} className="transformator__canvas-img-gl" />
                  <img src={defaultMask} className="transformator__canvas-img-mask" />

                  <img src="/images/transformator/default/img.jpg" className="transformator__canvas-img" />
                  <canvas id="faceapi" />

                  {/*<div className={!tumbler ? 'lds-dual-ring-wrap active' : 'lds-dual-ring-wrap'}>
                    <div className="lds-dual-ring" />
                  </div>*/}

                  <div className={tumbler ? 'transformator__matrix active' : 'transformator__matrix'}></div>
                </div>

                <div className="transformator__screen-status-wrap">
                  <div className="transformator__screen-status">
                    <div>портрет: {photo ? <span>загружен</span> : <span className="yellow">не загружен</span> }</div>
                    <div>статус трансформации: <span className={status == 'не выполнена' ? 'yellow' : null}>{status}</span></div>
                    { ( ( gpu.gpu && gpu.msg ) && status == 'Трансформировано') && (
                      <div className="pink">
                        В случае неудовлетворительного результата загрузите другое фото
                      </div>
                    )}
                    { ( ( !gpu.gpu && gpu.msg ) && status == 'Трансформировано') && (
                      <div className="pink">
                        Аппаратные характеристики системы не позволяют получить идеальный результат
                      </div>
                    )}
                  </div>
                </div>

                <div className={ ( photo && status == 'Трансформировано' ) ? 'transformator__soc active' : 'transformator__soc'}>
                  <img src="/images/transformator/new/title_share.svg" />
                  <div className="transformator__soc-fb" onClick={() => sharing('fb')}>
                    {/*<div dangerouslySetInnerHTML={{ __html: window.VK.Share.button({url: 'http://mysite.com', title: 'Заголовок страницы', image: shareImageVk, noparse: true}) }} />*/}
                    <img src="/images/transformator/fb.svg" />
                  </div>
                  <div className="transformator__soc-vk" onClick={() => sharing('vk')}>
                    <img src="/images/transformator/vk.svg" />
                  </div>
                  <img src="/images/transformator/new2/title_mail.svg" className="transformator__soc-mail-title" />
                  <div>
                    <img src="/images/transformator/new/mail.svg" onClick={sharingMail} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*<Link to="/" className="transformator__button1">
            <img className="transformator__button1-t" src="/images/transformator/b1t.png" />
            <img className="transformator__button1-b" src="/images/transformator/button1.png" />
          </Link>

          <Link to="/ratings" className="transformator__button2">
            <img className="transformator__button2-t" src="/images/transformator/b2t.png" />
            <img className="transformator__button2-b" src="/images/transformator/button2.png" />
          </Link>*/}


        </div>
      </div>
    </>
  )
}

export default Transformator;