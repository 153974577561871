import React, { Component, Fragment } from 'react'

class Icons extends Component {
  state = {
    icons: [],
    index: 1
  }

  id = null

  componentDidMount() {
    this.id = setInterval(() => {
      this.create()
    }, 400)
  }

  componentWillUnmount() {
    clearInterval(this.id)
  }

  render() {
    return <div className="level1__icons">{this.state.icons}</div>
  }

  create = () => {
    this.setState({
      icons: [
        ...this.state.icons,
        <div key={this.state.index} className={`l-icon2 l-anim-${this.getRandom(6, 1)} l-color-${this.getRandom(3, 1)}`}>
          <div></div>
        </div>
      ],
      index: this.state.index + 1
    })
  }

  getRandom = (min, max) => {
    var rand = min + Math.random() * (max - min)
    return Math.round(rand)
  }

}

export default Icons
