import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'

import { setLevel } from '../../redux/modules/access'

class Desctope extends Component {
  state = {
    moveLeft: true
  }

  render() {
    return (
      <div className="level4 page fade-in" onMouseMove={this.handleMouseMove}>
        <div className="level1__bg"><span  className={this.state.moveLeft ? 'hover-left' : 'hover-right'} ></span></div>
        <div className="content" style={this.props.mobile ? {height: this.props.height} : {}}>
          <div className="spacer spacer-middle">
            <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/spacer${this.props.mobile ? '_mobile' : ''}.png`} alt=""/>
            <div className="layer level4__agent">
              <img src="/images/level4/agent.png" alt=""/>
            </div>
            <div className="layer level4__check level4__check-1">
              <img src="/images/level4/check.svg" alt=""/>
            </div>
            <div className="layer level4__check level4__check-2">
              <img src="/images/level4/check.svg" alt=""/>
            </div>
            <div className="layer level4__check level4__check-3">
              <img src="/images/level4/check.svg" alt=""/>
            </div>
            <div className="layer level4__item">
              <img src="/images/level4/item.png" alt=""/>
            </div>
            <div className="layer level4__x8">
              <img src="/images/level4/x8.png" alt=""/>
              <span className="layer level4__x8-1"><img src="/images/level4/1.png" alt=""/></span>
              <span className="layer level4__x8-2"><img src="/images/level4/2.png" alt=""/></span>
              <span className="layer level4__x8-3"><img src="/images/level4/3.png" alt=""/></span>
              <span className="layer level4__x8-4"><img src="/images/level4/4.png" alt=""/></span>
              <span className="layer level4__x8-5"><img src="/images/level4/5.png" alt=""/></span>
            </div>
            <div className="layer level4__bubble">
              <img src="/images/level4/bubble.png" alt=""/>
            </div>
            <div className="layer level4__bubble2">
              <img src="/images/level4/bubble2.png" alt=""/>
            </div>
            {this.props.step === 1 &&
            <div className="btn-wrap btn-wrap-1">
              <div className="btn btn-pink" onClick={this.props.nextStep}>
                <span>ввести код</span>
                <span>в дезинтегратор</span>
              </div>
            </div>
            }
            {this.props.step === 2 &&
            <Fragment>
              <div className="layer level4__floor">
                <img src="/images/level4/floor.png" alt=""/>
              </div>
              <div className="layer level4__window1">
                <img src="/images/level4/window1.png" alt=""/>
              </div>
              <div className="layer level4__window2">
                <img src="/images/level4/window2.png" alt=""/>
              </div>
              <div className="layer level4__pc">
                <img src="/images/level4/pc.png" alt=""/>
              </div>
              <div className="layer level4__x82">
                <img src="/images/level4/x82.png" alt=""/>
              </div>
              <div className="layer level4__agent2">
                <img src="/images/level4/agent2.png" alt=""/>
              </div>

              <div className="layer level4__num level4__num1">
                <img src="/images/level4/num1.png" alt=""/>
              </div>
              <div className="layer level4__num level4__num2">
                <img src="/images/level4/num2.png" alt=""/>
              </div>
              <div className="layer level4__num level4__num3">
                <img src="/images/level4/num3.png" alt=""/>
              </div>
              <div className="layer level4__num level4__num4">
                <img src="/images/level4/num4.png" alt=""/>
              </div>
              <div className="layer level4__num level4__num5">
                <img src="/images/level4/num5.png" alt=""/>
              </div>
              <div className="layer level4__text">
                <img src="/images/level4/text.png" alt=""/>
              </div>

              <div className="btn-wrap btn-wrap-1">
                <Link to="/level-5" className="btn btn-yellow2" onClick={() => this.props.setLevel(5)}>
                  <span>скорее ввести</span>
                  <span>правильный код</span>
                  <i className="btn__arrow"></i>
                </Link>
              </div>
            </Fragment>
            }
          </div>
        </div>
      </div>
    )
  }

  handleMouseMove = (ev) => {
    const el = ev.currentTarget
    this.setState({
      moveLeft: ev.pageX > el.offsetWidth / 2
    })
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    height: state.height,
  }),
  {setLevel}
)(Desctope)
