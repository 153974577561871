import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Loader extends Component {
  static propTypes = {
    images: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingSuccess: PropTypes.func.isRequired
  }

  state = {
    loadedImages: 0
  }

  componentDidMount() {
    this.props.images.map((picture) => {
      const img = new Image()
      img.src = picture
      img.onload = () => this.setState({ loadedImages: this.state.loadedImages + 1 })
      img.onerror = () => {
        throw picture + ' not loaded'
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { loadedImages } = this.state
    const { images, loadingSuccess } = this.props

    if (loadedImages === images.length && loadedImages !== prevState.loadedImages) {
      loadingSuccess()
    }
  }

  render() {
    if (this.props.loading && !this.props.nextLoader) return <div className="loader">loading...</div>
    return null
  }
}

export default Loader
