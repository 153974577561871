import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'
ReactGA.initialize('UA-129422066-1', { debug: true })

const history = createBrowserHistory()
history.listen((location, action) => {
  ReactGA.set({ page: location.pathname })
  ReactGA.pageview(location.pathname)
})

export default history;
