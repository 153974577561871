import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import Icons from './icons'

import { setLevel } from '../../redux/modules/access'
import {getRandom} from '../../utils'

class Desctope extends Component {
  state = {
    moveLeft: true,
    clicked: null,
    status1: false,
    status2: false,
  }

  render() {
    return (
      <div className="level1 page fade-in" onMouseMove={this.handleMouseMove}>
        <div className="level1__bg"><span  className={this.state.moveLeft ? 'hover-left' : 'hover-right'} ></span></div>
        <div className="content" style={this.props.mobile ? {height: this.props.height} : {}} >
          <div className="spacer spacer-middle">
            <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/spacer${this.props.mobile ? '_mobile' : ''}.png`} alt=""/>
            {(this.props.step === 1 || this.props.step === 2) && 
              <Fragment>
                <div className="layer level1__girl">
                  <img src="/images/level1/girl.png" alt=""/>
                </div>
                <div className="layer level1__agent">
                  <img src={this.props.mobile ? '/images/level1/agent-mobile.png' : '/images/level1/agent.png'} alt=""/>
                </div>
                <div className="layer level1__bubble3">
                  {this.props.mobile ? <img src="/images/level1/bubble3-mobile.png" alt=""/> : <img src="/images/level1/bubble3.png" alt=""/> }
                </div>
                <div className="btn-wrap btn-wrap-1">
                  <div className="btn btn-pink" onClick={this.props.nextStep}>
                    <span>cейчас</span>
                    <span>узнаем код</span>
                  </div>
                </div>
              </Fragment>
            }
            {this.props.step > 1 && 
              <Fragment>
                <div className="layer level1__mat">
                  <img src="/images/level1/mat.png" alt=""/>
                </div>
                <div className="layer level1__rabbit" onClick={() => this.handleClick(1)}>
                  <img src="/images/level1/rabbit.png" alt=""/>
                  <div className="level1__eyebrow1"><img src="/images/level1/eyebrow1.png" alt=""/></div>
                  <div className="level1__eyebrow2"><img src="/images/level1/eyebrow2.png" alt=""/></div>
                </div>
                <div className="layer level1__bear" onClick={() => this.handleClick(2)}>
                  <img src="/images/level1/bear.png" alt=""/>
                  <div className="level1__tendril1"><img src="/images/level1/tendril1.png" alt=""/></div>
                  <div className="level1__tendril2"><img src="/images/level1/tendril2.png" alt=""/></div>
                </div>

                <div className="layer level1__yule" onClick={() => this.handleClick(3)}>
                  <img src="/images/level1/yule.png" alt=""/>
                  <Icons />
                </div>
                <div className="layer level1__toilet" onClick={() => this.handleClick(4)}>
                  <img src="/images/level1/toilet.png" alt=""/>
                </div>
                <div className="layer level1__text">
                  <img src="/images/level1/text.png" alt=""/>
                </div>
              </Fragment>
            }

            {this.state.clicked === 1 &&
              <div className="layer level1__b1">
                <img src="/images/level1/b1.png" alt=""/>
              </div>
            }
            {this.state.clicked === 2 &&
              <div className="layer level1__b2">
                <img src="/images/level1/b2.png" alt=""/>
              </div>
            }
            {this.state.clicked === 3 &&
              <div className="layer level1__b3">
                <img src="/images/level1/b3.png" alt=""/>
              </div>
            }
            {this.state.clicked === 4 &&
              <div className="layer level1__b4">
                <img src="/images/level1/b4.png" alt=""/>
              </div>
            }
          </div>
        </div>
        {this.modal}
      </div>
    )
  }

  get modal() {
    if (!this.state.status1 || !this.state.status2) return null
    return (
      <div className="btn-modal">
        <img className="btn-modal__txt" src="/images/txt.png" alt=""/>
        <div className="btn-modal__btn">
          <Link to="/level-2" className="btn btn-orange" onClick={() => this.props.setLevel(2)}>
            <span>искать новые цифры</span>
            <i className="btn__arrow"></i>
          </Link>
        </div>
      </div>
    )
  }

  handleClick = (clicked) => {
    const {status} = this.state
    this.setState({
      clicked, 
      status1: clicked === 1 ? true : this.state.status1,
      status2: clicked === 2 ? true : this.state.status2
    })
  }

  handleMouseMove = (ev) => {
    const el = ev.currentTarget
    this.setState({
      moveLeft: ev.pageX > el.offsetWidth / 2
    })
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    height: state.height,
  }),
  {setLevel}
)(Desctope)
