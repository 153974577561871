import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Header from '../layout/header'

import Mobile from './mobile'
import Desctope from './desctope'


import Loader from '../common/loader'
import { loadingSuccess as nextLoadingSuccess } from '../../redux/modules/finish'
import { loadingSuccess } from '../../redux/modules/level5'

import './style.css'

class Level5 extends Component {
  render() {
    if (this.props.loading) return <Loader loading={this.props.loading} images={this.props.images} loadingSuccess={this.props.loadingSuccess} />
    return (
      <Fragment>
        <Header style={2} />
        <div>
          <Desctope />
          {this.props.nextloading && <Loader nextLoader={true} loading={this.props.nextloading} images={this.props.nextimages} loadingSuccess={this.props.nextLoadingSuccess} />}
        </div>
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    loading: state.level5.loading,
    images: state.level5.images,
    nextloading: state.finish.loading,
    nextimages: state.finish.images,
  }),
  { loadingSuccess, nextLoadingSuccess  }
)(Level5)
