import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { hit } from '../common/metrics'

class Header extends Component {
  static propTypes = {
    style: PropTypes.number.isRequired
  }

  componentDidMount() {
    hit(window.location.pathname)
  }

  state = {
    open: false
  }

  render() {
    return (
      <Fragment>
        <div className={`header style-${this.props.style}`}>
          <div className="content">
            <Link to="/" className="logo"></Link>
          </div>
        </div>
      </Fragment>
    )
  }

}

export default Header
