import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'

import { setLevel } from '../../redux/modules/access'
import {getRandom} from '../../utils'

class Desctope extends Component {
  state = {
    moveLeft: true,
    clicked: null,
    status: false,
  }

  render() {
    return (
      <div className="level2 page fade-in" onMouseMove={this.handleMouseMove}>
        <div className="intro__bg"><span  className={this.state.moveLeft ? 'hover-left' : 'hover-right'} ></span></div>
        <div className="content" style={this.props.mobile ? {height: this.props.height} : {}}>
          <div className="spacer spacer-middle">
            <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/spacer${this.props.mobile ? '_mobile' : ''}.png`} alt=""/>
            {(this.props.step === 1 || this.props.step === 2) && 
              <Fragment>
                <div className="layer level2__circle">
                  <img src="/images/level2/circle.png" alt=""/>
                </div>
                <div className="layer level2__text">
                  <img src="/images/level2/text.png" alt=""/>
                </div>
                <div className="layer level2__circle2">
                  <img src="/images/level2/circle2.png" alt=""/>
                </div>
                <div className="layer level2__girl">
                  <img src="/images/level2/girl.png" alt=""/>
                </div>
                <div className="layer level2__splash">
                  <img src="/images/level2/splash.png" alt=""/>
                </div>
                <div className="layer level2__lines">
                  <img src="/images/level2/lines.png" alt=""/>
                </div>
                <div className="layer level2__shoe" onClick={() => this.handleClick(1)}>
                  <img src="/images/level2/shoe.png" alt=""/>
                </div>
                <div className="layer level2__bag" onClick={() => this.handleClick(4)}>
                  <img src="/images/level2/bag.png" alt=""/>
                </div>
                <div className="layer level2__hat" onClick={() => this.handleClick(3)}>
                  <img src="/images/level2/hat.png" alt=""/>
                </div>
                <div className="layer level2__pomade" onClick={() => this.handleClick(2)}>
                  <img src="/images/level2/pomade.png" alt=""/>
                </div>
              </Fragment>
            }


            {this.state.clicked === 1 &&
              <div className="layer level2__b1">
                <img src="/images/level2/b1.png" alt=""/>
              </div>
            }
            {this.state.clicked === 2 &&
              <div className="layer level2__b2">
                <img src="/images/level2/b2.png" alt=""/>
              </div>
            }
            {this.state.clicked === 3 &&
              <div className="layer level2__b3">
                <img src="/images/level2/b3.png" alt=""/>
              </div>
            }
            {this.state.clicked === 4 &&
              <div className="layer level2__b4">
                <img src="/images/level2/b4.png" alt=""/>
              </div>
            }
          </div>
        </div>
        {this.modal}
      </div>
    )
  }

  get modal() {
    if (this.state.status !== 1) return null
    return (
      <div className="btn-modal">
        <img className="btn-modal__txt" src="/images/txt.png" alt=""/>
        <div className="btn-modal__btn">
          <Link to="/level-3" className="btn btn-orange" onClick={() => this.props.setLevel(3)}>
            <span>искать новые цифры</span>
            <i className="btn__arrow"></i>
          </Link>
        </div>
      </div>
    )
  }

  handleClick = (clicked) => {
    const {status} = this.state
    this.setState({
      clicked, 
      status: (status < 1 && clicked === 1) ? status + 1 : status
    })
  }

  handleMouseMove = (ev) => {
    const el = ev.currentTarget
    this.setState({
      moveLeft: ev.pageX > el.offsetWidth / 2
    })
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    height: state.height,
  }),
  {setLevel}
)(Desctope)
