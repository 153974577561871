import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import history from './redux/history'
import { YMInitializer } from 'react-yandex-metrika'
import './css/app.css'

import PrivateRoute from './components/common/private-router'
import Footer from './components/layout/footer'
import Home from './components/home'
import Intro from './components/intro'
import Level1 from './components/level1'
import Level2 from './components/level2'
import Level3 from './components/level3'
import Level4 from './components/level4'
import Level5 from './components/level5'
import Finish from './components/finish'
import Transformator from './components/transformator';
import PostCard from './components/postcard';


import { setMobile } from './redux/modules/mobile'
import { setHeight } from './redux/modules/height'
import { closeErrorMessage } from './redux/modules/modal'
import { userIsAuthorized } from './redux/modules/access'

class App extends Component {
  componentDidMount() {
    this.props.setMobile(window.innerWidth <= 990)
    this.props.setHeight(window.innerHeight)
    window.addEventListener('resize', this._onResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._onResize)
  }

  render() {
    return (
      <ConnectedRouter history={history}>
        <div>
          <YMInitializer accounts={[51203048]} />
          
          <Switch>
            <Route path="/start" exact component={Home} />
            <Route path="/intro" exact component={this.intro} />
            <Route path="/level-1" exact component={this.level1} />
            <Route path="/level-2" exact component={this.level2} />
            <Route path="/level-3" exact component={this.level3} />
            <Route path="/level-4" exact component={this.level4} />
            <Route path="/level-5" exact component={this.level5} />
            <Route path="/finish" exact component={this.finish} />

            <Route path="/" exact component={Transformator} />
            <Route path="/postcard/:id" exact component={PostCard} />
          </Switch>
          <Footer />
        </div>
      </ConnectedRouter>
    )
  }

  intro = ({ match }) => {
    if (this.props.level < 0) return <Redirect to="/" />
    if (match.isExact) return <Intro />
  }

  level1 = ({ match }) => {
    if (this.props.level < 1) return <Redirect to="/intro" />
    if (match.isExact) return <Level1 />
  }

  level2 = ({ match }) => {
    if (this.props.level < 2) return <Redirect to="/level-1" />
    if (match.isExact) return <Level2 />
  }

  level3 = ({ match }) => {
    if (this.props.level < 3) return <Redirect to="/level-2" />
    if (match.isExact) return <Level3 />
  }

  level4 = ({ match }) => {
    if (this.props.level < 4) return <Redirect to="/level-3" />
    if (match.isExact) return <Level4 />
  }

  level5 = ({ match }) => {
    if (this.props.level < 5) return <Redirect to="/level-4" />
    if (match.isExact) return <Level5 />
  }

  finish = ({ match }) => {
    if (this.props.level < 6) return <Redirect to="/level-5" />
    if (match.isExact) return <Finish />
  }


  _onResize = () => {
    const mobile = window.innerWidth <= 990
    const height = window.innerHeight

    if ((mobile && !this.props.mobile) || (!mobile && this.props.mobile)) {
      this.props.setMobile(mobile)
    }
    if (Math.abs(height - this.props.height) > 100) {
      this.props.setHeight(height)
    }
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    height: state.height,
    level: state.access.level,
  }),
  { setMobile, setHeight }
)(App)
