// import axios from 'axios'
// import { api } from '../config'
// import { createSelector } from 'reselect'
import { push } from 'connected-react-router'

/**
 * Constants
 * */
export const moduleName = 'level5'
export const LOAD_SUCCESS = `${moduleName}/LOAD_SUCCESS`
export const LOAD_MOBILE_SUCCESS = `${moduleName}/LOAD_MOBILE_SUCCESS`

/**
 * Reducer
 * */
const defaultState = {
  images: [
    '/images/spacer.png',
    '/images/spacer_mobile.png',
    '/images/level5/bg.jpg',
    '/images/level5/pc.png',
    '/images/level5/x8.png',
    '/images/level5/agent.png',
    '/images/level5/bubble.png'
  ],
  images_mobile: [
    '/images/spacer.png',
  ],
  loading_mobile: true,
  loading: true
}

export default (state = defaultState, action) => {
  const { type } = action

  switch (type) {
    case LOAD_SUCCESS:
      return { ...state, loading: false }
      
    case LOAD_MOBILE_SUCCESS:
      return { ...state, loading_mobile: false }

    default:
      return state
  }
}

/**
 * Selectors
 * */
export const imagesSelector = (state) => state[moduleName].images
export const loadingSelector = (state) => state[moduleName].loading
export const loadingMobileSelector = (state) => state[moduleName].loading_mobile
export const imagesMobileSelector = (state) => state[moduleName].images_mobile

/**
 * Action Creators
 * */

export const loadingSuccess = () => {
  return {
    type: LOAD_SUCCESS
  }
}
export const loadingMobileSuccess = () => {
  return {
    type: LOAD_MOBILE_SUCCESS
  }
}



export const getPage = (url) => {
  return (dispatch) => {
    dispatch(push(url))
  }
}
