import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import history from './history'
import mobileReducer, { moduleName as mobileModule } from './modules/mobile'
import heightReducer, { moduleName as heightModule } from './modules/height'
import modalReducer, { moduleName as modalModule } from './modules/modal'
import accessReducer, { moduleName as accessModule } from './modules/access'
import homeReducer, { moduleName as homeModule } from './modules/home'
import introReducer, { moduleName as introModule } from './modules/intro'
import ratingsReducer, { moduleName as ratingsModule } from './modules/ratings'
import level1Reducer, { moduleName as level1Module } from './modules/level1'
import level2Reducer, { moduleName as level2Module } from './modules/level2'
import level3Reducer, { moduleName as level3Module } from './modules/level3'
import level4Reducer, { moduleName as level4Module } from './modules/level4'
import level5Reducer, { moduleName as level5Module } from './modules/level5'
import finishReducer, { moduleName as finishModule } from './modules/finish'

export default combineReducers({
  router: connectRouter(history),
  [modalModule]: modalReducer,
  [mobileModule]: mobileReducer,
  [heightModule]: heightReducer,
  [accessModule]: accessReducer,
  [homeModule]: homeReducer,
  [introModule]: introReducer,
  [ratingsModule]: ratingsReducer,
  [level1Module]: level1Reducer,
  [level2Module]: level2Reducer,
  [level3Module]: level3Reducer,
  [level4Module]: level4Reducer,
  [level5Module]: level5Reducer,
  [finishModule]: finishReducer,
})
