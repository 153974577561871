import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

class Desctope extends Component {
  state = {
    moveLeft: true
  }

  render() {
    return (
      <div className="finish page fade-in" onMouseMove={this.handleMouseMove}>
        <div className="level1__bg"><span  className={this.state.moveLeft ? 'hover-left' : 'hover-right'} ></span></div>
        <div className="content" style={this.props.mobile ? {height: this.props.height} : {}}>
          <div className="spacer spacer-middle">
            <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/spacer${this.props.mobile ? '_mobile' : ''}.png`} alt=""/>
            <div className="layer finish__floor">
              <img src="/images/finish/floor.png" alt=""/>
            </div>
            <div className="layer finish__palm">
              <img src="/images/finish/palm.png" alt=""/>
            </div>
            <div className="layer finish__palm2">
              <img src="/images/finish/palm2.png" alt=""/>
            </div>
            <div className="layer finish__lines">
              <img src="/images/finish/lines.png" alt=""/>
            </div>
            <div className="layer finish__circle">
              <img src="/images/finish/circle.png" alt=""/>
            </div>
            <div className="layer finish__pc">
              <img src="/images/finish/pc.png" alt=""/>
            </div>
            <div className="layer finish__text">
              <img src="/images/finish/text.png" alt=""/>
            </div>
            <div className="layer finish__txt">
              {this.props.mobile ? <img src="/images/finish/txt-mobile.png" alt=""/> : <img src="/images/finish/txt.png" alt=""/>}
            </div>
            <div className="layer finish__txt2">
              {this.props.mobile ? <img src="/images/finish/txt2.png" alt=""/> : <img src="/images/finish/txt2.png" alt=""/>}
            </div>
            <div className="btn-wrap">
              <Link to="/" className="btn btn-pink2">
                <span>протестировать</span>
                <span>трансформатор</span>
                <i className="btn__arrow"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

  handleMouseMove = (ev) => {
    const el = ev.currentTarget
    this.setState({
      moveLeft: ev.pageX > el.offsetWidth / 2
    })
  }

}

export default connect(
  (state) => ({
    mobile: state.mobile,
    height: state.height,
  }),
  {}
)(Desctope)
