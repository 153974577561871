import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'

import { setLevel } from '../../redux/modules/access'
import {getRandom} from '../../utils'

class Desctope extends Component {
  state = {
    moveLeft: true,
    clicked: null,
    status: false,
  }

  render() {
    return (
      <div className="level3 page fade-in" onMouseMove={this.handleMouseMove}>
        <div className="level3__bg"><span  className={this.state.moveLeft ? 'hover-left' : 'hover-right'} ></span></div>
        <div className="content" style={this.props.mobile ? {height: this.props.height} : {}}>
          <div className="spacer">
            <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/spacer${this.props.mobile ? '_mobile' : ''}.png`} alt=""/>
            <div className="layer level3__text">
              <img src="/images/level3/text.png" alt=""/>
            </div>
            <div className="layer level3__x8">
              <img src="/images/level3/x8.png" alt=""/>
            </div>
            <div className="layer level3__heart level3__heart-1">
              <img src="/images/level3/heart1.png" alt=""/>
            </div>
            <div className="layer level3__heart level3__heart-2">
              <img src="/images/level3/heart2.png" alt=""/>
            </div>
            <div className="layer level3__heart level3__heart-3">
              <img src="/images/level3/heart3.png" alt=""/>
            </div>
            <div className="layer level3__heart level3__heart-4">
              <img src="/images/level3/heart4.png" alt=""/>
            </div>
          </div>
          <div className="spacer spacer-top">
            <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/spacer${this.props.mobile ? '_mobile' : ''}.png`} alt=""/>
            <div className="layer level3__agent" >
              <img src="/images/level3/agent.png" alt=""/>
            </div>


            {this.state.clicked === 1 &&
              <div className="layer level3__b1">
                <img src="/images/level3/b1.png" alt=""/>
              </div>
            }
            {this.state.clicked === 2 &&
              <div className="layer level3__b2">
                <img src="/images/level3/b2.png" alt=""/>
              </div>
            }
          </div>
          {this.state.status !== 1 &&
            <Fragment>
              <div className="helper1" onClick={() => this.handleClick(2)}></div>
              <div className="helper2" onClick={() => this.handleClick(1)}></div>
            </Fragment>
          }
          {this.modal}
        </div>
      </div>
    )
  }

  get modal() {
    if (this.state.status !== 1) return null
    return (
      <div className="btn-modal">
        <img className="btn-modal__txt" src="/images/txt.png" alt=""/>
        <div className="btn-modal__btn">
          <Link to="/level-4" className="btn btn-orange" onClick={() => this.props.setLevel(4)}>
            <span>можно ввести код</span>
            <span>в дезинтегратор</span>
            <i className="btn__arrow"></i>
          </Link>
        </div>
      </div>
    )
  }

  handleClick = (clicked) => {
    const {status} = this.state
    this.setState({
      clicked, 
      status: (status < 1 && clicked === 1) ? status + 1 : status
    })
  }

  handleMouseMove = (ev) => {
    const el = ev.currentTarget
    this.setState({
      moveLeft: ev.pageX > el.offsetWidth / 2
    })
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    height: state.height,
  }),
  {setLevel}
)(Desctope)
