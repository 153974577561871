import React, { useState, useEffect } from 'react';
import Header from '../layout/header';
import { Link, withRouter } from 'react-router-dom';
import { api } from '../../redux/config';
import axios from 'axios';
import './style.css'

const PostCard = ({match}) => {

  const [img, setImage] = useState(null);
  const [text, setText] = useState(null);

  useEffect(() => {
    const hash = match.params.id;
  
    axios.get(api + 'postcard-json.php', { params: { hash } })
    .then(function (response) {
      if(response.data.success){
        setImage(response.data.image);
        setText(response.data.text);
      }
    })
    .catch(function (error) {
      console.log(error);
    });

  }, []);

  return(
    <div className="tpostcard">
      <Header style={1} />
      <div className="postcard-wrap fade-in">
        <div className="postcard-wrap__in">
          <img className="postcard-wrap__in-img1" src={ img } />
          {img && (
            <>
              <img className="postcard-wrap__in-img2" src="/images/transformator/new2/end4.png" />
              <img className="postcard-wrap__in-img3" src="/images/transformator/new2/end5.png" />
              <img className="postcard-wrap__in-img4" src="/images/transformator/new2/end6.png" />
              <img className="postcard-wrap__in-img5" src="/images/transformator/new2/end7.png" />
              <img className="postcard-wrap__in-img6" src="/images/transformator/new2/end8.png" />
            </>
          )}
          <div className="postcard-wrap__text" dangerouslySetInnerHTML={{ __html: text}} />

          <Link to="/" className="postcard-wrap__button">
            <img src="/images/transformator/new2/end2.png" />
            <img src="/images/transformator/new2/end3.png" />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default withRouter(PostCard);