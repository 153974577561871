import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Header from '../layout/header'

import Mobile from './mobile'
import Desctope from './desctope'

import Modal from '../modal/modal-call-by-mail'
import ErrorMessage from '../modal/error'
import SuccessMessage from '../modal/success'

import './style.css'

class Finish extends Component {
  render() {
    return (
      <Fragment>
        <Header style={2} />
        <div>
          <Desctope />
        </div>
      </Fragment>
    )
  }

}

export default connect(
  (state) => ({
    mobile: state.mobile,
  }),
  {}
  
)(Finish)
