import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'

import { loadingMobileSelector, imagesMobileSelector, loadingMobileSuccess as loadingSuccess } from '../../redux/modules/level3'

class Mobile extends Component {
  state = {
    moveLeft: true
  }

  render() {
    const { loading, images, loadingSuccess } = this.props
    if (loading) return <Loader loading={loading} images={images} loadingSuccess={loadingSuccess} />

    return (
      <div className="intro-mobile page fade-in"></div>
    )
  }
}

export default connect(
  (state) => ({
    loading: loadingMobileSelector(state),
    images: imagesMobileSelector(state),
  }),
  {loadingSuccess}
)(Mobile)
