import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'

import { setLevel } from '../../redux/modules/access'

class Desctope extends Component {
  render() {
    return (
      <div className="level5 page fade-in">
        <div className="level5__bg" />
        <div className="content" style={this.props.mobile ? {height: this.props.height} : {}}>
          <div className="spacer spacer-middle">
            <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/spacer${this.props.mobile ? '_mobile' : ''}.png`} alt=""/>
            <div className="layer level5__pc">
              <img src="/images/level5/pc.png" alt=""/>
            </div>
          </div>
          <div className="spacer spacer-left">
            <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/spacer${this.props.mobile ? '_mobile' : ''}.png`} alt=""/>
            <div className="layer level5__x8">
              <img src="/images/level5/x8.png" alt=""/>
            </div>
            <div className="layer level5__agent">
              <img src="/images/level5/agent.png" alt=""/>
            </div>
            <div className="layer level5__bubble">
              <img src="/images/level5/bubble.png" alt=""/>
            </div>
          </div>
          <div className="spacer spacer-middle">
            <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/spacer${this.props.mobile ? '_mobile' : ''}.png`} alt=""/>
            <div className="btn-wrap btn-wrap-1">
              <Link to="/finish" className="btn btn-yellow3" onClick={() => this.props.setLevel(6)}>
                <span>ура</span>
                <i className="btn__arrow"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default connect(
  (state) => ({
    mobile: state.mobile,
    height: state.height,
  }),
  {setLevel}
)(Desctope)
