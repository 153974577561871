import React, { Component } from 'react'

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="content">
          <div>
            <div className="footer__icons">
              <a className="footer__ico footer__ico1" href="https://www.x5.ru/ru" target="_blank" rel="noopener noreferrer">
                <img src="/images/p1.svg" alt="" />
              </a>
              <a className="footer__ico footer__ico2" href="https://5ka.ru/" target="_blank" rel="noopener noreferrer">
                <img src="/images/p2.svg" alt="" />
              </a>
              <a className="footer__ico footer__ico3" href="https://karusel.ru/" target="_blank" rel="noopener noreferrer">
                <img src="/images/p3.svg" alt="" />
              </a>
              <a className="footer__ico footer__ico4" href="https://www.perekrestok.ru/" target="_blank" rel="noopener noreferrer">
                <img src="/images/p4.svg" alt="" />
              </a>
              <a className="footer__ico footer__ico5" href="https://www.x5.ru/ru" target="_blank" rel="noopener noreferrer">
                <img src="/images/p5.svg" alt="" />
              </a>
            </div>
            © 2019 «Х5 Retail Group». Все права защищены.
          </div>
          <div>
            Сделано в{' '}
            <a href="http://www.amio.ru/" target="_blank" rel="noopener noreferrer">
              A M I O
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
