import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { setLevel } from '../../redux/modules/access'

class Desctope extends Component {
  state = {
    moveLeft: true
  }

  render() {
    return (
      <div className="intro page fade-in" onMouseMove={this.handleMouseMove}>
        <div className="intro__bg"><span  className={this.state.moveLeft ? 'hover-left' : 'hover-right'} ></span></div>
        <div className="content" style={this.props.mobile ? {height: this.props.height} : {}} >
          <div className="spacer spacer-top">
            <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/spacer${this.props.mobile ? '_mobile' : ''}.png`} alt=""/>
            <div className="layer intro__circle">
              <img src="/images/home/circle-large.png" alt=""/>
            </div>
          </div>
          <div className="spacer spacer-middle">
            <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/spacer${this.props.mobile ? '_mobile' : ''}.png`} alt=""/>
            <div className="layer intro__circle2">
              <img src="/images/home/circle-large.png" alt=""/>
            </div>
            <div className="layer intro__text">
              <img src="/images/intro/text.png" alt=""/>
            </div>
            <div className="layer intro__text2">
              {this.props.mobile ?<img src="/images/intro/text2-mobile.png" alt=""/> : <img src="/images/intro/text2.png" alt=""/>}
            </div>
            <div className="layer intro__1">
              {this.props.mobile ?<img src="/images/intro/1-mobile.png" alt=""/> : <img src="/images/intro/1.png" alt=""/>}
            </div>
            <div className="layer intro__arrow">
              <img src="/images/intro/arrow.png" alt=""/>
            </div>
            <div className="layer intro__2">
              {this.props.mobile ?<img src="/images/intro/2-mobile.png" alt=""/> : <img src="/images/intro/2.png" alt=""/>}
            </div>
            <div className="layer intro__arrow2">
              <img src="/images/intro/arrow.png" alt=""/>
            </div>
            <div className="layer intro__3">
              {this.props.mobile ?<img src="/images/intro/3-mobile.png" alt=""/> : <img src="/images/intro/3.png" alt=""/>}
            </div>
            <div className="layer intro__arrow">
              <img src="/images/intro/arrow.svg" alt=""/>
            </div>
            <div className="layer intro__arrow2">
              <img src="/images/intro/arrow.svg" alt=""/>
            </div>
            <div className="btn-wrap">
              <Link className="btn btn-black" to="/level-1" onClick={() => {this.props.setLevel(1)}}>
                <span>я все помню</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

  handleMouseMove = (ev) => {
    const el = ev.currentTarget
    this.setState({
      moveLeft: ev.pageX > el.offsetWidth / 2
    })
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    height: state.height,
  }),
  {setLevel}
)(Desctope)
