import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Header from '../layout/header'

import Mobile from './mobile'
import Desctope from './desctope'

import Loader from '../common/loader'
import { loadingSuccess as nextLoadingSuccess } from '../../redux/modules/level3'
import { loadingSuccess } from '../../redux/modules/level2'
import './style.css'

class Level2 extends Component {
  state = {
    step: 1
  }
  render() {
    if (this.props.loading) return <Loader loading={this.props.loading} images={this.props.images} loadingSuccess={this.props.loadingSuccess} />
    return (
      <Fragment>
        <Header style={1} />
        <div className={`step-${this.state.step}`}>
          <Desctope nextStep={this.nextStep} step={this.state.step} />
          {this.props.nextloading && <Loader nextLoader={true} loading={this.props.nextloading} images={this.props.nextimages} loadingSuccess={this.props.nextLoadingSuccess} />}
        </div>

      </Fragment>
    )
  }

  nextStep = () => this.setState({ step: this.state.step + 1 })
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    loading: state.level2.loading,
    images: state.level2.images,
    nextloading: state.level3.loading,
    nextimages: state.level3.images,
  }),
  { loadingSuccess, nextLoadingSuccess  }
)(Level2)
