import axios from 'axios'
import { api } from '../config'

/**
 * Constants
 * */
export const moduleName = 'ratings'
export const GET_RATINGS = `${moduleName}/GET_RATINGS`


/**
 * Reducer
 * */

export default (state = null, action) => {
  const { type, response } = action

  if (type === GET_RATINGS) {
    return {
      ...state,
      ...response
    }
  }

  return state
}

/**
 * Action Creators
 * */
export const getRatings = () => {
  return (dispatch) =>
    axios
      .get(api + 'result-json.php')
      .then(({ data }) => data)
      .then((response) => dispatch({ type: GET_RATINGS, response }))
}

