import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import CanvasAnimation from '../common/canvas-animation'

import { setLevel } from '../../redux/modules/access'

class Desctope extends Component {
  state = {
    moveLeft: true
  }

  render() {
    return (
      <div className={this.state.moveLeft ? 'home page fade-in hover-left' : 'home page fade-in hover-right'} onMouseMove={this.handleMouseMove}>
        <div className={this.state.moveLeft ? 'home__bg1 hover-left' : 'home__bg1 hover-right'} />
        <div className={this.state.moveLeft ? 'home__bg2 hover-left' : 'home__bg2 hover-right'} />
        <div className="content" style={this.props.mobile ? {height: this.props.height} : {}} >
          <div className="spacer home__spacer-top">
            <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/spacer${this.props.mobile ? '_mobile' : ''}.png`} alt=""/>
            <div className="layer home__lamp1">
              <img src="/images/home/lamp1.png" alt=""/>
              <div className="layer home__light">
                <img src="/images/home/light1.svg" alt=""/>
              </div>
              <div className="layer home__light2">
                <img src="/images/home/light2.svg" alt=""/>
              </div>
            </div>
            <div className="layer home__lamp2">
              <img src="/images/home/lamp2.png" alt=""/>
            </div>
          </div>
          <div className="spacer spacer-left">
            <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/spacer${this.props.mobile ? '_mobile' : ''}.png`} alt=""/>
            <div className="layer home__lamp3">
              <img src="/images/home/lamp3.png" alt=""/>
              <div className="layer home__light3">
                <img src="/images/home/light3.svg" alt=""/>
              </div>
              <div className="layer home__light4">
                <img src="/images/home/light4.svg" alt=""/>
              </div>
            </div>
          </div>
          <div className="spacer">
            <img style={this.props.mobile ? {maxHeight: this.props.height} : {}} src={`/images/spacer${this.props.mobile ? '_mobile' : ''}.png`} alt=""/>
              <div className="layer home__circle1">
                <img src="/images/home/circle.png" alt=""/>
              </div>
              <div className="layer home__circle2">
                <img src="/images/home/circle.png" alt=""/>
              </div>
              <div className="layer home__circle3">
                <img src="/images/home/circle.png" alt=""/>
              </div>
              <div className="layer home__circle4">
                <img src="/images/home/circle.png" alt=""/>
              </div>
              <div className="layer home__shadow">
                <img src="/images/home/shadow.png" alt=""/>
              </div>
              <div className="layer home__sense">
                <img src="/images/home/circle-large.png" alt=""/>
              </div>
              <div className="layer home__girl">
                <img src="/images/home/girl.png" alt=""/>
              </div>
              <div className="layer home__headphones">
                <CanvasAnimation src="/images/home/headphones.png" size={[121, 183]} fw={121} steps={8} speed={1} />
              </div>
              <div className="layer home__text">
                {this.props.mobile ? <img src="/images/home/text-mobile.png" alt=""/> : <img src="/images/home/text.png" alt=""/>}
                <div className="btn-wrap">
                  <Link className="btn btn-yellow" to="/intro" onClick={() => this.props.setLevel(0)}>
                    <span>вспомнить всё!</span>
                  </Link>
                </div>
              </div>
          </div>
        </div>
      </div>
    )
  }

  handleMouseMove = (ev) => {
    const el = ev.currentTarget
    this.setState({
      moveLeft: ev.pageX > el.offsetWidth / 2
    })
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    height: state.height
  }),
  {setLevel}
)(Desctope)
